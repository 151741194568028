import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getVendingPointConditions() {
    return axiosIns.get('/admin/vending_point_conditions')
  },
  createVendingPointCondition({
    adjective,
    threshold,
    vendingPointTemplateId,
    calcSalesTarget,
    tableFilterId,
  }) {
    const formData = serialize(snakecaseKeys({
      vendingPointCondition: {
        adjective,
        threshold,
        vendingPointTemplateId,
        calcSalesTarget,
        tableFilterId,
      },
    }))

    return axiosIns.post('/admin/vending_point_conditions', formData)
  },
  updateVendingPointCondition({
    id,
    adjective,
    threshold,
    vendingPointTemplateId,
    calcSalesTarget,
    tableFilterId,
  }) {
    const formData = serialize(snakecaseKeys({
      vendingPointCondition: {
        adjective,
        threshold,
        vendingPointTemplateId,
        calcSalesTarget,
        tableFilterId,
      },
    }))

    return axiosIns.put(`/admin/vending_point_conditions/${id}`, formData)
  },
  deleteVendingPointCondition(id) {
    return axiosIns.delete(`/admin/vending_point_conditions/${id}`)
  },
}
