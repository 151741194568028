import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getVendingPointTemplates() {
    return axiosIns.get('/admin/vending_point_templates')
  },
  createVendingPointTemplate({
    name,
    description,
    vendingPointsAttributes,
  }) {
    const formData = serialize(snakecaseKeys({
      vendingPointTemplate: {
        name,
        description,
        vendingPointsAttributes,
      },
    }))

    return axiosIns.post('/admin/vending_point_templates', formData)
  },
  updateVendingPointTemplate({
    id,
    name,
    description,
    vendingPointsAttributes,
  }) {
    const formData = serialize(snakecaseKeys({
      vendingPointTemplate: {
        name,
        description,
        vendingPointsAttributes,
      },
    }))

    return axiosIns.put(`/admin/vending_point_templates/${id}`, formData)
  },
  deleteVendingPointTemplate(id) {
    return axiosIns.delete(`/admin/vending_point_templates/${id}`)
  },
}
