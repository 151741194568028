<template>
  <v-skeleton-loader
    v-if="isLoading"
    type="table"
  />

  <div v-else>
    <v-tabs
      v-model="currentTabIdx"
      show-arrows
      background-color="transparent"
      class="elevation-0"
    >
      <v-tab
        v-for="text in ['条件', 'ポイント表']"
        :key="`tab-for-${text}`"
      >
        {{ text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="currentTabIdx"
      style="background: transparent"
      touchless
    >
      <v-tab-item>
        <common-conditions
          v-model="currentForm"
          :headers-ext="headers"
          :items="vendingPointConditions"
          :validator="currentForm => currentForm.vendingPointTemplateId && currentForm.calcSalesTarget"
          threshold-suffix="pt"
          :is-submitting="isSubmitting"
          :display-functions="displayFunctions"
          class="pt-3"
          @submit="submit"
          @cancel="cancelForm"
          @edit="currentForm = { id: $event.id, ...$event.attributes }"
          @destroy="destroy($event)"
        >
          <template #input>
            <v-select
              :value="currentForm"
              :items="vendingPointTemplates"
              :item-value="item => {
                if (item.attributes) return { vendingPointTemplateId: item.id }
              }"
              :value-comparator="(a, b) => {
                if (a && b) return +a.vendingPointTemplateId === +b.vendingPointTemplateId
              }"
              item-text="attributes.name"
              label="ポイント表"
              :error="!currentForm.vendingPointTemplateId"
              no-data-text="選択可能なデータがありません"
              :menu-props="{ auto: true, offsetY: true }"
              @change="currentForm.vendingPointTemplateId = +$event.vendingPointTemplateId"
            />

            <v-row class="mt-3">
              <v-col cols="6">
                <v-select
                  :value="currentForm"
                  :items="tableFilters"
                  :item-value="item => {
                    if (item.attributes) return { tableFilterId: item.id }
                  }"
                  :value-comparator="(a, b) => {
                    if (a && b) return +a.tableFilterId === +b.tableFilterId
                  }"
                  item-text="attributes.name"
                  label="卓フィルタ"
                  placeholder="指定なし"
                  persistent-placeholder
                  no-data-text="選択可能なデータがありません"
                  :suffix="currentForm.tableFilterId ? 'の卓の' : ''"
                  clearable
                  :menu-props="{ auto: true, offsetY: true }"
                  @change="currentForm.tableFilterId = $event ? $event.tableFilterId : null"
                  @click:clear="currentForm.tableFilterId = null"
                />
              </v-col>

              <v-col cols="6">
                <v-select
                  :value="currentForm.calcSalesTarget"
                  :items="calcSalesTargets"
                  item-value="value"
                  item-text="text"
                  label="pt加算対象"
                  :menu-props="{ auto: true, offsetY: true }"
                  :error="!currentForm.calcSalesTarget"
                  @change="currentForm.calcSalesTarget = $event"
                >
                  <template #selection="{ item }">
                    <span>
                      <v-icon
                        v-if="item.icon"
                        left
                        small
                      >
                        {{ item.icon }}
                      </v-icon>
                      {{ item.text }}
                    </span>
                  </template>

                  <template #item="{ item }">
                    <v-list-item-avatar
                      v-if="item.icon"
                      size="20"
                    >
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </template>

          <template #group-header="{ groupBy, group }">
            <strong v-if="groupBy[0] === 'attributes.vendingPointTemplateId'">
              {{ vendingPointTemplates.find(o => +o.id === +group).attributes.name }}
            </strong>

            <strong v-if="groupBy[0] === 'meta.calcSalesTargetName'">
              {{ group }}
            </strong>
          </template>
        </common-conditions>
      </v-tab-item>

      <v-tab-item>
        <vending-point-templates
          :vending-point-templates="vendingPointTemplates"
          :vending-point-conditions="vendingPointConditions"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from '@vue/composition-api'
import { findIndex } from 'lodash'
import { mdiCashMarker, mdiHeartOutline } from '@mdi/js'
import VendingPointTemplateApi from '@/api/admin/VendingPointTemplate'
import VendingPointConditionApi from '@/api/admin/VendingPointCondition'
import TableFilterApi from '@/api/admin/TableFilter'
import useCompInit from '@/views/composable/useCompInit'
import VendingPointTemplates from '@/views/components/pay_condition/VendingPointTemplates.vue'
import CommonConditions from '@/views/components/pay_condition/CommonConditions.vue'

export default {
  components: {
    CommonConditions,
    VendingPointTemplates,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { isLoading, initWith } = useCompInit()
    const isSubmitting = ref(false)
    const currentTabIdx = ref(0)
    const currentForm = ref({
      id: null,
      adjective: null,
      threshold: 0,
      vendingPointTemplateId: null,
      calcSalesTarget: 'contributed',
      tableFilterId: null,
    })
    const vendingPointConditions = ref([])
    const vendingPointTemplates = ref([])
    const tableFilters = ref([])
    const calcSalesTargets = [
      { icon: mdiCashMarker, value: 'contributed', text: '売上' },
      { icon: mdiHeartOutline, value: 'received', text: '受領' },
      { value: 'both', text: '両方' },
    ]

    const cancelForm = () => {
      currentForm.value = {
        id: null,
        adjective: null,
        threshold: 0,
        vendingPointTemplateId: null,
        calcSalesTarget: 'contributed',
        tableFilterId: null,
      }
    }

    const submit = async () => {
      isSubmitting.value = true

      const isUpdate = !!currentForm.value.id
      const fnVerb = isUpdate ? 'update' : 'create'
      const {
        id,
        adjective,
        threshold,
        vendingPointTemplateId,
        calcSalesTarget,
        tableFilterId,
      } = currentForm.value

      const res = await VendingPointConditionApi[`${fnVerb}VendingPointCondition`]({
        id,
        adjective,
        threshold,
        vendingPointTemplateId,
        calcSalesTarget,
        tableFilterId,
      })

      if (res?.data) {
        const { data } = res.data.vendingPointCondition

        if (isUpdate) {
          const vendingPointConditionIdx = findIndex(vendingPointConditions.value, o => +o.id === +data.id)
          vendingPointConditions.value.splice(vendingPointConditionIdx, 1, data)
        } else {
          vendingPointConditions.value.push(data)
        }

        vm.$toast.success(isUpdate ? '条件を更新しました' : '条件を追加しました')
      }

      cancelForm()
      isSubmitting.value = false
    }

    const destroy = async id => {
      const res = await VendingPointConditionApi.deleteVendingPointCondition(id)

      if (res) {
        const vendingPointConditionIdx = findIndex(vendingPointConditions.value, o => +o.id === +id)
        vendingPointConditions.value.splice(vendingPointConditionIdx, 1)
        vm.$toast.success('条件を削除しました')
      }
    }

    const getVendingPointConditions = async () => {
      const res = await VendingPointConditionApi.getVendingPointConditions()

      if (res?.data) {
        vendingPointConditions.value = [...res.data.vendingPointConditions.data]
      }
    }

    const getVendingPointTemplates = async () => {
      const res = await VendingPointTemplateApi.getVendingPointTemplates()

      if (res?.data) {
        vendingPointTemplates.value = [...res.data.vendingPointTemplates.data]
      }
    }

    const getTableFilters = async () => {
      const res = await TableFilterApi.getTableFilters()

      if (res?.data) {
        tableFilters.value = [...res.data.tableFilters.data]
      }
    }

    initWith([
      getVendingPointConditions(),
      getVendingPointTemplates(),
      getTableFilters(),
    ])

    return {
      // data
      isLoading,
      isSubmitting,
      currentTabIdx,
      currentForm,
      vendingPointConditions,
      vendingPointTemplates,
      tableFilters,
      headers: [
        {
          text: 'ポイント表',
          value: 'attributes.vendingPointTemplateId',
        },
        {
          text: 'pt加算対象',
          value: 'meta.calcSalesTargetName',
        },
      ],
      calcSalesTargets,
      displayFunctions: {
        'attributes.vendingPointTemplateId': id => vendingPointTemplates.value.find(o => +o.id === +id).attributes.name,
      },

      // methods
      cancelForm,
      submit,
      destroy,
    }
  },
}
</script>
