import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getTableFilters() {
    return axiosIns.get('/admin/table_filters')
  },
  createTableFilter({
    name,
    tableFilterVendiblesAttributes,
    tableFilterReferralsAttributes,
    tableFilterCustomerTagsAttributes,
  }) {
    const formData = serialize(snakecaseKeys({
      tableFilter: {
        name,
        tableFilterVendiblesAttributes,
        tableFilterReferralsAttributes,
        tableFilterCustomerTagsAttributes,
      },
    }))

    return axiosIns.post('/admin/table_filters', formData)
  },
  updateTableFilter({
    id,
    name,
    tableFilterVendiblesAttributes,
    tableFilterReferralsAttributes,
    tableFilterCustomerTagsAttributes,
  }) {
    const formData = serialize(snakecaseKeys({
      tableFilter: {
        name,
        tableFilterVendiblesAttributes,
        tableFilterReferralsAttributes,
        tableFilterCustomerTagsAttributes,
      },
    }))

    return axiosIns.put(`/admin/table_filters/${id}`, formData)
  },
  deleteTableFilter(id) {
    return axiosIns.delete(`/admin/table_filters/${id}`)
  },
}
