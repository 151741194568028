var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('div',[_c('v-tabs',{staticClass:"elevation-0",attrs:{"show-arrows":"","background-color":"transparent"},model:{value:(_vm.currentTabIdx),callback:function ($$v) {_vm.currentTabIdx=$$v},expression:"currentTabIdx"}},_vm._l((['条件', 'ポイント表']),function(text){return _c('v-tab',{key:("tab-for-" + text)},[_vm._v(" "+_vm._s(text)+" ")])}),1),_c('v-tabs-items',{staticStyle:{"background":"transparent"},attrs:{"touchless":""},model:{value:(_vm.currentTabIdx),callback:function ($$v) {_vm.currentTabIdx=$$v},expression:"currentTabIdx"}},[_c('v-tab-item',[_c('common-conditions',{staticClass:"pt-3",attrs:{"headers-ext":_vm.headers,"items":_vm.vendingPointConditions,"validator":function (currentForm) { return currentForm.vendingPointTemplateId && currentForm.calcSalesTarget; },"threshold-suffix":"pt","is-submitting":_vm.isSubmitting,"display-functions":_vm.displayFunctions},on:{"submit":_vm.submit,"cancel":_vm.cancelForm,"edit":function($event){_vm.currentForm = Object.assign({}, {id: $event.id}, $event.attributes)},"destroy":function($event){return _vm.destroy($event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{attrs:{"value":_vm.currentForm,"items":_vm.vendingPointTemplates,"item-value":function (item) {
              if (item.attributes) { return { vendingPointTemplateId: item.id } }
            },"value-comparator":function (a, b) {
              if (a && b) { return +a.vendingPointTemplateId === +b.vendingPointTemplateId }
            },"item-text":"attributes.name","label":"ポイント表","error":!_vm.currentForm.vendingPointTemplateId,"no-data-text":"選択可能なデータがありません","menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){_vm.currentForm.vendingPointTemplateId = +$event.vendingPointTemplateId}}}),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"value":_vm.currentForm,"items":_vm.tableFilters,"item-value":function (item) {
                  if (item.attributes) { return { tableFilterId: item.id } }
                },"value-comparator":function (a, b) {
                  if (a && b) { return +a.tableFilterId === +b.tableFilterId }
                },"item-text":"attributes.name","label":"卓フィルタ","placeholder":"指定なし","persistent-placeholder":"","no-data-text":"選択可能なデータがありません","suffix":_vm.currentForm.tableFilterId ? 'の卓の' : '',"clearable":"","menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){_vm.currentForm.tableFilterId = $event ? $event.tableFilterId : null},"click:clear":function($event){_vm.currentForm.tableFilterId = null}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"value":_vm.currentForm.calcSalesTarget,"items":_vm.calcSalesTargets,"item-value":"value","item-text":"text","label":"pt加算対象","menu-props":{ auto: true, offsetY: true },"error":!_vm.currentForm.calcSalesTarget},on:{"change":function($event){_vm.currentForm.calcSalesTarget = $event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('span',[(item.icon)?_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.text)+" ")],1)]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [(item.icon)?_c('v-list-item-avatar',{attrs:{"size":"20"}},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}}])})],1)],1)]},proxy:true},{key:"group-header",fn:function(ref){
                var groupBy = ref.groupBy;
                var group = ref.group;
return [(groupBy[0] === 'attributes.vendingPointTemplateId')?_c('strong',[_vm._v(" "+_vm._s(_vm.vendingPointTemplates.find(function (o) { return +o.id === +group; }).attributes.name)+" ")]):_vm._e(),(groupBy[0] === 'meta.calcSalesTargetName')?_c('strong',[_vm._v(" "+_vm._s(group)+" ")]):_vm._e()]}}]),model:{value:(_vm.currentForm),callback:function ($$v) {_vm.currentForm=$$v},expression:"currentForm"}})],1),_c('v-tab-item',[_c('vending-point-templates',{attrs:{"vending-point-templates":_vm.vendingPointTemplates,"vending-point-conditions":_vm.vendingPointConditions}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }