<template>
  <v-skeleton-loader
    v-if="isLoading"
    type="table"
  />

  <div
    v-else
    class="pt-3"
  >
    <div class="pa-0 px-1 pb-3 w-full d-flex align-center justify-end">
      <v-dialog
        v-model="isFormOpen"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            :ripple="false"
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="buildVendingPointItems"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>

        <v-card class="pt-8">
          <v-app-bar fixed>
            <v-btn
              icon
              :ripple="false"
              @click="cancelForm"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
            <v-app-bar-title>
              {{ currentForm.id ? 'ポイント表 編集' : '新規 ポイント表' }}
            </v-app-bar-title>
          </v-app-bar>

          <v-card-text class="mt-5 pt-15 px-10">
            <v-skeleton-loader
              v-if="isLoading || vendingPointItems.length <= 0"
              type="table"
            />

            <div v-else>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="currentForm.name"
                    :error="!currentForm.name"
                    label="名前"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-textarea
                    v-model="currentForm.description"
                    label="説明"
                    rows="2"
                    :prepend-icon="icons.mdiCommentQuoteOutline"
                  />
                </v-col>
              </v-row>

              <v-card class="mt-4">
                <v-card-title>ポイント</v-card-title>

                <v-card-text>
                  <Menu
                    :vendibles="vendingPointItems"
                    :vendible-tags="vendibleTags"
                    :no-cursor-pointer="true"
                  >
                    <template #action="{ item }">
                      <v-text-field
                        v-model.number="item.vendingPoint.amount"
                        type="number"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        min="0"
                        suffix="pt"
                      />
                    </template>
                  </Menu>
                </v-card-text>
              </v-card>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :ripple="false"
              color="primary"
              :disabled="!currentForm.name"
              :loading="isSubmitting"
              @click="submit"
            >
              {{ currentForm.id ? '更新' : '作成' }}
            </v-btn>
            <v-btn
              :ripple="false"
              color="blue darken-1"
              text
              @click="cancelForm"
            >
              キャンセル
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-data-table
      :headers="headers"
      :items="vendingPointTemplates"
      :header-props="{ sortByText: 'ソート' }"
      hide-default-footer
      disable-pagination
      no-data-text="データがありません"
    >
      <template #[`item.actions`]="{item}">
        <span class="d-flex align-center justify-end">
          <v-btn
            icon
            :ripple="false"
            @click="
              currentForm = { id: item.id, ...item.attributes };
              buildVendingPointItems(item.id);
              isFormOpen = true"
          >
            <v-icon small>
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </v-btn>

          <yona-edit-dialog
            title="削除の確認"
            btn-color="error"
            save-text="同意の上で削除"
            @save="destroy(item.id)"
          >
            <template #append-outer-display-name>
              <v-btn
                icon
                :ripple="false"
              >
                <v-icon small>
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </template>

            <template #input>
              <span>
                削除を実行してもよろしいですか
              </span>
            </template>
          </yona-edit-dialog>
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  ref,
  computed,
  watch,
  getCurrentInstance,
} from '@vue/composition-api'
import {
  mdiPlus,
  mdiClose,
  mdiCommentQuoteOutline,
  mdiTrashCanOutline,
  mdiPencilOutline,
} from '@mdi/js'
import {
  map,
  find,
  flatMap,
  findIndex,
} from 'lodash'
import VendibleApi from '@/api/waiter/Vendible'
import VendibleTagApi from '@/api/waiter/VendibleTag'
import VendingPointTemplateApi from '@/api/admin/VendingPointTemplate'
import useCompInit from '@/views/composable/useCompInit'
import Menu from '@/views/components/vendible/Menu.vue'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'

export default {
  components: {
    Menu,
    YonaEditDialog,
  },
  props: {
    vendingPointTemplates: {
      type: Array,
      required: true,
      default: () => [],
    },
    vendingPointConditions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const { isLoading, initWith } = useCompInit()
    const isFormOpen = ref(false)
    const isSubmitting = ref(false)
    const currentForm = ref({
      id: null,
      name: '',
      description: '',
    })
    const vendibles = ref([])
    const vendibleTags = ref([])
    const vendingPointItems = ref([])

    const vendingPointsAttributes = computed(() => {
      return flatMap(vendingPointItems.value, o => {
        const {
          id,
          amount,
          vendibleId,
          vendibleType,
        } = o.vendingPoint

        if (amount && vendibleId && vendibleType) return o.vendingPoint

        if (id && !amount && vendibleId && vendibleType) return { ...o.vendingPoint, _destroy: true }

        return []
      })
    })

    const buildVendingPointItems = (id = null) => {
      const vendingPointTemplate = find(props.vendingPointTemplates, o => +o.id === +id)
      const vendingPoints = vendingPointTemplate?.attributes?.vendingPoints?.data || []

      vendingPointItems.value = map(vendibles.value, vendible => {
        const vendingPoint = find(vendingPoints, o => {
          return +o.attributes.vendibleId === +vendible.id
            && o.attributes.vendibleType === vendible.attributes.vendibleType
        })

        return {
          ...vendible,
          vendingPoint: {
            id: vendingPoint?.id || null,
            vendibleId: vendible.id,
            vendibleType: vendible.attributes.vendibleType,
            amount: vendingPoint?.attributes?.amount || null,
          },
        }
      })
    }

    const cancelForm = () => {
      isFormOpen.value = false
      currentForm.value = {}
      vendingPointItems.value = []
    }

    const submit = async () => {
      if (!currentForm.value.name) return

      isSubmitting.value = true

      const isUpdate = !!currentForm.value.id
      const fnVerb = isUpdate ? 'update' : 'create'
      const {
        id,
        name,
        description,
      } = currentForm.value

      const res = await VendingPointTemplateApi[`${fnVerb}VendingPointTemplate`]({
        id,
        name,
        description,
        vendingPointsAttributes: vendingPointsAttributes.value,
      })

      if (res?.data) {
        const { data } = res.data.vendingPointTemplate

        if (isUpdate) {
          const vendingPointTemplateIdx = findIndex(props.vendingPointTemplates, o => +o.id === +data.id)
          props.vendingPointTemplates.splice(vendingPointTemplateIdx, 1, data)
        } else {
          props.vendingPointTemplates.push(data)
        }

        vm.$toast.success(isUpdate ? 'ポイント表を更新しました' : 'ポイント表を追加しました')
      }

      cancelForm()
      isSubmitting.value = false
    }

    const destroy = async id => {
      const vendingPointCondition = find(props.vendingPointConditions, o => {
        return +o.attributes.vendingPointTemplateId === +id
      })

      if (vendingPointCondition) {
        vm.$toast.error('依存している条件があるため削除できません')

        return
      }

      const res = await VendingPointTemplateApi.deleteVendingPointTemplate(id)

      if (res) {
        const vendingPointTemplateIdx = findIndex(props.vendingPointTemplates, o => +o.id === +id)
        props.vendingPointTemplates.splice(vendingPointTemplateIdx, 1)
        vm.$toast.success('ポイント表を削除しました')
      }
    }

    const getVendibles = async () => {
      const res = await VendibleApi.getVendibles()

      if (res?.data) {
        vendibles.value = [...res.data.vendibles.data]
      }
    }

    const getVendibleTags = async () => {
      const res = await VendibleTagApi.getVendibleTags()

      if (res?.data) {
        vendibleTags.value = [...res.data.vendibleTags.data]
      }
    }

    initWith([
      getVendibles(),
      getVendibleTags(),
    ])

    watch(() => isFormOpen.value, () => {
      emit('toggle-form', isFormOpen.value)
    })

    return {
      // data
      isLoading,
      isFormOpen,
      isSubmitting,
      currentForm,
      vendibles,
      vendibleTags,
      vendingPointItems,
      headers: [
        {
          text: '名前',
          value: 'attributes.name',
        },
        {
          text: '説明',
          value: 'attributes.description',
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
          groupable: false,
        },
      ],

      // computed
      vendingPointsAttributes,

      // methods
      buildVendingPointItems,
      cancelForm,
      submit,
      destroy,

      icons: {
        mdiPlus,
        mdiClose,
        mdiCommentQuoteOutline,
        mdiTrashCanOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>
